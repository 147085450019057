exports.components = {
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-generator-index-js": () => import("./../../../src/pages/generator/index.js" /* webpackChunkName: "component---src-pages-generator-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microprofile-index-js": () => import("./../../../src/pages/microprofile/index.js" /* webpackChunkName: "component---src-pages-microprofile-index-js" */),
  "component---src-pages-opinions-index-js": () => import("./../../../src/pages/opinions/index.js" /* webpackChunkName: "component---src-pages-opinions-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-tutorials-develop-microservices-with-java-ee-and-kumuluzee-index-js": () => import("./../../../src/pages/tutorials/develop-microservices-with-java-ee-and-kumuluzee/index.js" /* webpackChunkName: "component---src-pages-tutorials-develop-microservices-with-java-ee-and-kumuluzee-index-js" */),
  "component---src-pages-tutorials-index-js": () => import("./../../../src/pages/tutorials/index.js" /* webpackChunkName: "component---src-pages-tutorials-index-js" */)
}

